<template>
  <v-main>
  <v-container
      id="user-login"
      fluid
      tag="section"
  >
    <v-row justify="center">
      <v-col
          cols="12"
          md="6"
      >
        <base-material-card
          icon="mdi-login"
        >
          <div class="subtitle-1 font-weight-light">
            Log in to your account
          </div>

          <v-form ref="loginForm"
                  @submit.prevent="validate"
                  v-model="valid"
                  lazy-validation
          >
            <v-container class="py-0">

              <div data-cy="login-error">
                <v-alert
                    type="error"
                    outlined
                    v-for="(error, i) in errors"
                    :key="i"
                >
                  {{ error }}
                </v-alert>
              </div>


              <v-row justify="center">
                <v-col cols="12" md="8">
                  <v-text-field
                      label="Username"
                      :id="username"
                      v-model="username"
                      type="email"
                      :rules="rules.username"
                      validate-on-blur
                  />
                </v-col>
              </v-row>

              <v-row justify="center">
                <v-col cols="12" md="8">
                  <v-text-field
                      label="Password"
                      :id="password"
                      v-model="password"
                      type="password"
                      :rules="rules.password"
                  />
                </v-col>
              </v-row>

              <v-row justify="center">
                <v-col cols="12" md="8">
                  <v-btn color="success"
                         type="submit"
                         class="mr-0"
                         data-cy="login-button">
                    Login
                  </v-btn>
                </v-col>
              </v-row>

              <v-row justify="center">
                <v-col cols="12" md="9">
                  <p>
                  Trouble logging in? <router-link :to="{name: 'reset_password'}">Click here to reset your password.</router-link>
                  </p>
                </v-col>
              </v-row>

            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  </v-main>
</template>

<script>
import {mapMutations, mapGetters} from 'vuex'

import api from '@/services/api'
import validation from "@/utils/validation"
import security from "@/utils/security"

export default {
  name: "CoreLoginView",
  components: {
  },
  created() {
    this.errorCode = this.$route.query.errorCode
    this.checkLogin()
    this.parseErrorCode()
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'getIsLoggedIn'
    })
  },
  methods: {
    ...mapMutations({
      setAuthorization: 'setAuthorization',
      setIsLoggedIn: 'setIsLoggedIn'
    }),
    validate () {
      this.errors = []
      if(this.$refs.loginForm.validate()) {
        this.submitLogin()
      }
    },
    parseErrorCode() {
      if (this.errorCode) {
        let errorMessage = this.errorMap[this.errorCode] || `Error Code: ${this.errorCode}`
        this.errors.push(errorMessage)
      }
    },
    submitLogin() {
      this.$gtag.event('login')
      api.authenticate(this.username, this.password)
          .then(response => {
            const auth = security.buildAuthorization(response.data)
            this.setAuthorization(auth)
            this.setIsLoggedIn()
            api.fetchUser(auth.userId)
                .then(() => {
                  this.$router.push('/account/view')
                })
                .catch(err => {
                  this.handleError(err)
                })
          })
          .catch(err => {
            this.handleError(err)
          });
    },
    checkLogin() {
      if (Number.parseInt(this.errorCode) === 401) {
        this.setAuthorization(null)
      }
      this.setIsLoggedIn()
      if (this.isLoggedIn) {
        this.$router.push(`/account/view`)
      }
    },
    handleError(err) {
      let errorMessage = api.parseResponseError(err, true)
      this.errors.push(errorMessage)
    }
  },
  data() {
    return {
      valid: true,
      username: null,
      password: null,
      errorCode: null,
      errors: [],
      errorMap: {
        401: 'Invalid username/password',
        403: 'You are not logged in or your login session has expired'
      },
      rules: {
        password: [
          v => validation.isRequired(v) || 'required'
        ],
        username: [
          v => validation.isRequired(v) || 'required',
          v => validation.isEmailAddress(v) || 'must be a valid email address'
        ]
      }
    }
  }
};

</script>
